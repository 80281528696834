import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import GlobalSettings from '@/GlobalSettings'

const entity = {}

const config = {
  load: async function (start, end, lang = 'DE') {
    const res = (await hillo.get('BackendData.php?op=dishStatistic', {
      lang: GlobalSettings.getLang(), start: start, end: end,
    })).content

    return res
  },
  printOrSendMail: async function (op, dates, dishCategoryIds, dishCategoryTypeIds, hideAttributeIds) {
    return (await hillo.post('Dishes.php?op=' + op, {
      start: dates[0],
      end: dates[1],
      dishCategoryIds: dishCategoryIds,
      dishCategoryTypeIds: dishCategoryTypeIds,
      hideAttributeIds: hideAttributeIds,
    }))
  },

}

export default IKDataEntity.ModelFactory(entity, config)
