<template>
  <div>
    <div>
      <div class="text-overline">
        {{ $t(text) }}
        <span
          v-if="required"
          class="red--text text-body-1"
        >*</span>
      </div>
    </div>
    <div class="mr-2">
      <v-select
        v-model="localValue"
        :placeholder="$t(placeholder)"
        :disabled="disabled"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :multiple="multiple"
        outlined
        :menu-props="Object.assign({offsetY:true, outlined:true,contentClass:'elevation-2 ikRounded',nudgeBottom:'16px'},menuProps)"
        :rules="rules"
        @click:clear="$emit('clear')"
      >
        <template
          v-if="hideSelect"
          #selection=" {item, index}"
        >
          <span
            v-if="index == 0"
            class="grey--text text-caption"
          >
            {{ $t(text) }} (+{{ localValue.length || 1 }})
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AadenSelect',
    props: {
      text: {
        type: String, default: '',
      },
      required: {
        type: Boolean, default: false,
      },
      value: {
        type: Object,
        default: () => {
        },
      },
      itemText: {
        type: String, default: '',
      },
      itemValue: {
        type: String, default: '',
      },
      rules: {
        type: Object,
        default: () => {
        },
      },
      multiple: {
        type: Boolean, default: false,
      },
      menuProps: {
        type: Object,
        default: () => {
        },
      },
      placeholder: {
        type: String, default: '',
      },
      disabled: {
        type: Boolean, default: false,
      },
      items: {
        type: Object,
        default: () => {
        },
      },
      hideSelect: {
        type: Boolean, default: false,
      },
    },
    data () {
      return {
        localValue: this.value,
      }
    },
    watch: {
      localValue (val) {
        this.$emit('input', val)
      },
      value: {
        immediate: true,
        handler (val) {
          this.localValue = val
        },
      },
    },
  }
</script>

<style scoped>

</style>
