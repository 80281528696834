<template>
  <v-card
    class="pa-4"
    color="#efefef"
    elevation="0"
  >
    <template v-if="!success">
      <div style="display: grid;grid-template-columns: repeat(2, 1fr); grid-column-gap: 16px">
        <div class="flex-grow-1">
          <div class="pa-1">
            <date-range-picker
              v-model="dates"
            />
          </div>
          <div
            v-if="dates"
            class="mt-4 pa-1"
          >
            <h3>
              {{ $t('chooseOtherConditions') }}
            </h3>
            <div>
              <div class="text-overline">
                {{ $t('chooseNeedInclude') }}{{ $t('dishCategoryType') }}
              </div>
              <div>
                <v-select
                  v-model="selectFilter.selectCategoryTypeIds"
                  :items="dishCategoryTypeList"
                  :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
                  :placeholder="$t('dishCategoryType')"
                  item-text="name"
                  hide-details
                  dense
                  item-value="id"
                  multiple
                  outlined
                  @click:clear="$emit('clear')"
                >
                  <template
                    #selection=" {item, index}"
                  >
                    <span
                      v-if="index == 0"
                      class="grey--text text-caption"
                    >
                      {{ $t('dishCategoryType') }} (+{{ selectFilter.selectCategoryTypeIds.length || 1 }})
                    </span>
                  </template>
                </v-select>
              </div>

              <div
                class="text-overline"
              >
                {{ $t('chooseNeedInclude') }}{{ $t('dishCategory') }}
              </div>
              <v-select
                v-model="selectFilter.selectCategoryIds"
                :items="dishCategoryList"
                :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
                :placeholder="$t('dishCategory')"
                item-text="_langsname"
                dense
                hide-details
                item-value="id"
                multiple
                outlined
                @click:clear="$emit('clear')"
              >
                <template
                  #selection=" {item, index}"
                >
                  <span
                    v-if="index == 0"
                    class="grey--text text-caption"
                  >
                    {{ $t('dishCategory') }} (+{{ selectFilter.selectCategoryIds.length || 1 }})
                  </span>
                </template>
              </v-select>

              <div class="text-overline">
                {{ $t('chooseNotNeedInclude') }}{{ $t('dishAttribute') }}
              </div>
              <v-select
                v-model="selectFilter.selectHideAttributeIds"
                :items="hideAttributeList"
                :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom:'64px'}"
                :placeholder="$t('dishAttribute')"
                item-text="name"
                dense
                hide-details
                item-value="id"
                multiple
                outlined
                @click:clear="$emit('clear')"
              >
                <template
                  #selection=" {item, index}"
                >
                  <span
                    v-if="index == 0"
                    class="grey--text text-caption"
                  >
                    {{ $t('dishAttribute') }} (+{{ selectFilter.selectHideAttributeIds.length || 1 }})
                  </span>
                </template>
              </v-select>

              <div>
                <v-btn
                  block
                  class="mt-2"
                  outlined
                  style="background-color: white; color: black"
                  @click="handleClear"
                >
                  {{ $t('cleanSelect') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="dates"
          class="pa-1 flex-grow-1"
        >
          <div class="d-flex align-center">
            <h3>{{ $t('searchResults') }}</h3>
          </div>
          <p class="my-2">
            {{ $t('numberOfDishes', { filterListLength: filterDishStatisticList.length }) }}
          </p>
          <div
            class="mr-4"
          >
            <send-to-email-button
              :loading="loading"
              :disabled="filterDishStatisticList.length<=0"
              @click="printOrSendMail('sendToMail')"
            />
            <v-btn
              :disabled="filterDishStatisticList.length<=0"
              :loading="loading"
              block
              class="mt-4"
              color="primary"
              elevation="0"
              @click="printOrSendMail('printWithCode')"
            >
              {{ $t('printByDishNumber') }}
            </v-btn>

            <v-btn
              :disabled="filterDishStatisticList.length<=0"
              :loading="loading"
              block
              class="mt-4"
              color="primary"
              elevation="0"
              @click="printOrSendMail('print')"
            >
              {{ $t('print') }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="height: 400px">
        <h3>{{ $t('success') }}</h3>
        <p>{{ $t('need30seconds') }}</p>
        <v-btn
          color="primary"
          outlined
          @click="reset"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('returnToHomeAndExportOther') }}
        </v-btn>
      </div>
    </template>
    <v-dialog
      v-model="showDateMenu"
      max-width="400px"
    >
      <v-card
        class="pa-2"
        color="#efefef"
        @click="showDateMenu=false"
      >
        <date-range-picker
          v-model="dates"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import DateRangePicker from '@/composable/DateRangePicker'
  import DishStatistic from '@/model/export/DishStatistic'
  import { isBlank } from '@/common/Utlis/api'
  import Category from '@/model/dish/Category'
  import CategoryType from '@/model/dish/CategoryType'
  import Attribute from '@/model/dish/Attribute'
  import AadenSelect from '@/views/AadenPages/export/childComponent/AadenSelect'
  import IKUtils from 'innerken-js-utils'
  import SendToEmailButton from '@/views/AadenPages/components/SendToEmailButton.vue'
  import { emptyOrIncludes } from '@/common/utils'

  export default {
    name: 'DishStatistic',
    components: {
      SendToEmailButton,
      AadenSelect,
      DateRangePicker,
    },
    data () {
      return {
        dates: null,
        dishStatisticList: [],
        model: DishStatistic,
        CategoryModel: Category,
        CategoryTypeModel: CategoryType,
        AttributeModel: Attribute,

        loading: false,
        success: false,
        selectFilter: {
          selectCategoryIds: [],
          selectCategoryTypeIds: [],
          selectHideAttributeIds: [],
        },
        dishCategoryList: [],
        dishCategoryTypeList: [],
        hideAttributeList: [],
        showDateMenu: false,
        showSelectFilterDialog: false,
      }
    },
    computed: {
      filterDishStatisticList () {
        if (this.selectFilterIsBlank) {
          return this.dishStatisticList
        } else {
          console.log(this.dishStatisticList, this.selectFilter.selectCategoryTypeIds)
          return this.dishStatisticList
            .filter(item =>
              emptyOrIncludes(this.selectFilter.selectCategoryIds, parseInt(item.categoryId)) &&
              emptyOrIncludes(this.selectFilter.selectCategoryTypeIds, parseInt(item.categoryTypeId)),
            ) ?? []
        }
      },
      selectFilterIsBlank () {
        return Object.entries(this.selectFilter).every(i => isBlank(i[1]))
      },
    },
    watch: {
      dates: {
        immediate: true,
        handler (val) {
          if (val) {
            this.loadList()
          }
        },
      },
    },
    async mounted () {
      this.success = false
      this.dishCategoryList = await this.CategoryModel.getList(true)
      this.dishCategoryTypeList = await this.CategoryTypeModel.getList(true)
      this.hideAttributeList = await this.AttributeModel.getList(true)
    },
    methods: {
      reset () {
        this.success = false
        this.loading = false
      },
      async loadList () {
        this.loading = true
        this.dishStatisticList = await this.model.load(...this.dates)
        this.loading = false
      },
      async printOrSendMail (op) {
        this.loading = true

        const filter = [this.selectFilter.selectCategoryIds?.toString(),
                        this.selectFilter.selectCategoryTypeIds?.toString(),
                        this.selectFilter.selectHideAttributeIds?.toString()]

        try {
          if (op === 'print') {
            await this.model.printOrSendMail('printSalesBon', this.dates, ...filter)
          } else if (op === 'printWithCode') {
            await this.model.printOrSendMail('printSalesBonByDishCode', this.dates, ...filter)
          } else {
            await this.model.printOrSendMail('sendSalesBonEmail', this.dates, ...filter)
          }
        } catch (e) {
          IKUtils.showError(e)
        }

        this.success = true
        this.loading = false
      },

      handleClear () {
        this.showSelectFilterDialog = false
        this.dates = null
        this.selectFilter = {
          selectCategoryIds: [],
          selectCategoryTypeIds: [],
          selectHideAttributeIds: [],
        }
      },

    },

  }
</script>

<style scoped>

</style>
