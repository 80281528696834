<template>
  <v-btn
    :disabled="disabled||!zbonEmail"
    :loading="loading"
    block
    color="primary lighten-4 black--text"
    elevation="0"
    @click="$emit('click')"
  >
    <v-icon left>
      mdi-email
    </v-icon>
    <template v-if="zbonEmail">
      {{ $t('sendToZbon', { zbonEmail: zbonEmail }) }}
    </template>
    <template v-else>
      {{ $t('ConfigureMail') }}
    </template>
  </v-btn>
</template>

<script>
  import { getNiceRestaurantInfo } from '@/common/Utlis/api'

  export default {
    name: 'SendToEmailButton',
    props: {
      disabled: {},
      loading: {},
    },
    data: function () {
      return {
        zbonEmail: '',
      }
    },
    async mounted () {
      this.zbonEmail = (await getNiceRestaurantInfo()).ZBonEmail
    },
  }
</script>

<style scoped>

</style>
