<template>
  <v-card
    class="pa-4"
    color="#efefef"
    elevation="0"
  >
    <template v-if="!success">
      <div style="display: grid;grid-template-columns: repeat(2,1fr)">
        <date-range-picker
          v-model="dates"
          class="mr-4"
        />
        <div
          v-if="dates"
          class="pa-1 flex-grow-1"
        >
          <div class="d-flex align-center">
            <h3>{{ $t('searchResult') }}</h3>
          </div>
          <p class="mt-4">
            {{ $t('thereAreZBons', { length: zbonList.length }) }}
          </p>
          <div class="mt-4">
            <div
              v-for="z in zbonList"
              :key="z.zbonNumber"
              class="d-flex align-center"
            >
              <v-simple-checkbox
                :value="selectedZBonIdList.includes(z.zbonNumber)"
                @click="toggle(z.zbonNumber)"
              />
              <div class="text-body-1">
                {{ z.zbonNumber }}
              </div>
              <v-spacer/>
              <div class="text-body-1">
                <v-icon left>
                  mdi-clock-outline
                </v-icon>
                {{ z.toTimestamp |beautifulTime }}
              </div>
            </div>
          </div>
          <send-to-email-button
            class="mt-4"
            :disabled="selectedZBonIdList.length===0"
            :loading="loading"
            @click="outputPdf"
          />
          <v-btn
            :disabled="selectedZBonIdList.length===0"
            :loading="loading"
            block
            class="mt-2"
            color="info"
            elevation="0"
            @click="reprintZbon"
          >
            {{ $t('reprint') }}
          </v-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="height: 400px">
        <h3>{{ $t('success') }}</h3>
        <p class="mt-4">
          {{ $t('exportedCountZBon', { length: selectedZBonIdList.length }) }}
        </p>
        <p>{{ $t('need30seconds') }}</p>
        <v-btn
          elevation="0"
          color="primary black--text lighten-4"
          @click="reset"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('returnToHomeAndExportOther') }}
        </v-btn>
      </div>
    </template>
  </v-card>
</template>

<script>
  import DateRangePicker from '@/composable/DateRangePicker'
  import Zbon from '@/model/export/Zbon'
  import { getNiceRestaurantInfo, newGetZBon, newPrintZBon } from '@/common/Utlis/api'
  import SendToEmailButton from '@/views/AadenPages/components/SendToEmailButton.vue'
  import dayjs from 'dayjs'

  export default {
    name: 'ZbonExport',
    components: {
      SendToEmailButton,
      DateRangePicker,
    },
    data: function () {
      return {
        dates: null,
        zbonList: [],
        selectedZBonIdList: [],
        model: Zbon,
        zbonEmail: '',
        loading: false,
        success: false,
      }
    },
    watch: {
      dates: {
        immediate: true,
        handler (val) {
          if (val) {
            this.loadZBonList()
          }
        },
      },
    },
    async mounted () {
      this.zbonEmail = (await getNiceRestaurantInfo()).ZBonEmail
      this.success = false
    },
    methods: {
      reset () {
        this.success = false
        this.loading = false
        this.dates = null
        this.selectedZBonIdList = []
      },
      async loadZBonList () {
        const fromTime = this.dates[0] + ' 04:00:00'
        const toTime = dayjs(this.dates[1]).add(1, 'day').format('YYYY-MM-DD') + ' 03:59:59'
        const detailTime = [fromTime, toTime]
        this.zbonList = (await newGetZBon(detailTime))
        this.selectedZBonIdList = this.zbonList.map(it => it.zbonNumber)
      },
      toggle (id) {
        if (this.selectedZBonIdList.includes(id)) {
          this.selectedZBonIdList = this.selectedZBonIdList.filter(it => it !== id)
        } else {
          this.selectedZBonIdList.push(id)
        }
      },
      async reprintZbon () {
        this.loading = true
        try {
          for (const i of this.selectedZBonIdList) {
            await newPrintZBon(i)
          }
        } catch (e) {

        }
        this.success = true
        this.loading = false
      },
      async outputPdf () {
        this.loading = true
        try {
          for (const i of this.selectedZBonIdList) {
            await newPrintZBon(i, 1, 1)
          }
        } catch (e) {

        }
        this.success = true
        this.loading = false
      },
    },
  }
</script>

<style scoped>

</style>
